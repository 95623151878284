const newRoomEndpoint = `${window.location.origin}/api/rooms`;

/**
 * Create a short-lived room for demo purposes.
 *
 * It uses the redirect proxy as specified in netlify.toml`
 * This will work locally if you following the Netlify specific instructions
 * in README.md
 *
 * See https://docs.daily.co/reference#create-room for more information on how
 * to use the Daily REST API to create rooms and what options are available.
 */
async function createRoom(perId) {

  // console.log('window.location',window.location)
  // const params = new URL(window.location.href).searchParams;
  // const [roomUrl] = [params.get('roomUrl')];
  // console.log('rooUrl', params.get('roomUrl'),roomUrl)

  const response = await fetch('https://tavusapi.com/v2/conversations', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "x-api-key": '9da3e77cc3e24046ba6079077f0be0be',
    },
    body: JSON.stringify({
      "persona_id":perId || "p7fb0be3",
      "properties": {
        "max_call_duration": 3600 * 2,
      "language": 'Chinese'
      }
    }),
  })
  console.log('responese', response)
  return response.json();

  // Comment out the above and uncomment the below, using your own URL
  // return { conversation_url: 'https://dujiabo.daily.co/MxMu8pgSk9tOkYfWMhR2' }
}

export default { createRoom };
