import React from 'react';
import './BrowserUnsupported.css';

export default function BrowserUnsupported() {
  return (
    <p class="browser-unsupported">
      当前浏览器不支持 Daily video ，请升级浏览器，您可点击
      <br />
      查看&nbsp;
      <a href="https://docs.daily.co/docs/browsers">当前页</a>
      &nbsp;查看所支持的浏览器及版本.
      <br />
      或者你当前网站不安全 已被禁用了摄像头和麦克风功能，请改为 https 协议。
    </p>
  );
}
